import React from "react"
import AboutSection from "../components/About/About"

const About = () => {
  return (
    <>
        <AboutSection />
    </>
  )
}

export default About
